/* Estilos generales del contenedor del modal. */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    background-Color: #00000088;
    width: 100vw;
    height: 100vh;
    z-Index: 1000;
    overflow: auto;
    padding: 2rem;
}

/* Estilos para el contenido del modal. */
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    background-color: #282c34;
    border: 2px solid #4ec3e0;
    min-width: 45rem;
    margin: auto;
}

/* Estilos para el cuerpo del modal. */
.modal-body {
    display: flex;
    width: 100%;
}

/* Estilos para la descripcion. */
.modal-description {
    margin: 1rem 2rem;
}

/* Estilos para los skills. */
.modal-skill {
    min-width: 13rem;
}

/* Estilos para el contenedor de imagenes. */
.images-container {
    aspect-ratio: 1/1;
    max-width: 45rem;
    max-height: 25rem;
    display: flex;
    flex: 1;
    margin-inline: 2rem;
    border-radius: 1rem;
    border: solid 2px #4ec3e0;
    overflow: hidden;
}

/* Estilos para las imagens del contenedor. */
.images-container img {
    width: 100%;
    height: 100%;
    margin: auto;
}

/* Configuracion responsive para los dispositivos de pantalla pequeñas. */
@media (max-device-width: 767px) {

    .modal-content {
        min-width: 10rem;
    }

    .modal-body {
        flex-direction: column;
    }
}

/* Configuracion responsive para los dipositivos de pantallas medianas. */
@media (min-device-width: 768px) and (max-device-width:1023px) {}

/* Configuracion responsive para los dipositivos de pantallas grandes */
@media (min-device-width: 1023px) {

    .modal-content {
        width: 60vw;
    }

    .images-container {
        min-width: 15rem;
        min-height: 15rem;
        width: 100%;
        height: 100%;
    }
}
