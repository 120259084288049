/* Estilos para el contenedor principal de una tarjeta de certificado. */
.certificateCard-container {
    display: flex;
    max-width: 22rem;
    width: 100%;
    background-color: #282c34cc;
    border: 2px solid #4ec3e0;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
    gap: 1rem;
    transition: transform 0.3s ease;
}

/* Estilos para la imagen de la tarjeta de certificados. */
.imageCard {
    display: flex;
    aspect-ratio: 1/1;
    max-width: 6rem;
    max-height: 6rem;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

/* Estilos para el texto de la tarjeta de certificados. */
.textCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    letter-spacing: 0.1rem;
    color: whitesmoke;
}

/* Configuracion responsive para dispositivos de pantallas pequeñas. */
@media (max-device-width: 767px) {}

/* Configuracion responsive para dispositivos de pantallas medianas. */
@media (min-device-width: 768px) and (max-device-width:1023px) {}

/* Configuracion responsive para dispositivos de pantallas grandes. */
@media (min-device-width: 1024px) {
    .certificateCard-container:hover {
        box-shadow: 0px 0px 10px 1px #4ec3e0;
        cursor: pointer;
        transform: translateY(-4px) translateX(-2px);
    }
}