/********** Configuracion de los contenedores **********/

/* Estilos del contenedor principal de la intro.  */
.container-intro {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-Color: #282c34;
    width: 100%;
    height: 100lvh;
    z-Index: 1000;
}

/* Estilos del contenedor principal del logo. */
.container-logo {
    display: flex;
    flex-direction: row;
}

/* Estilos del subcontenedor del logo. */
.subcontainer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    gap: 0.5rem;
}


/********** Configuracion de las imagenes **********/

/* Estilos de logoG */
.logoG {
    width: 6rem;
    height: 6rem;
}

/* Estilos de logoDivisor */
.logoDivisor {
    height: 6rem;
}

/* Estilos de logoGtorres */
.logoGtorres {
    width: 20.6rem;
    height: 4rem;
}

/* Estilos de logoDeveloper. */
.logoDeveloper {
    width: 20.6rem;
}

/* Estilos de loading */
.loading {
    padding: 1rem;
}


/********** Configuracion de las distintas entradas de las imagenes **********/

/* Estilos para la aparicion de logoG. */
.display-logoG {
    width: 0rem;
    height: 6rem;
    animation: curtain-logo1 1s forwards;
    animation-delay: 2.5s;
    overflow: hidden;
}

/* Estilos para la aparicion de logoDivisor. */
.display-logoDivisor {
    width: 0.2rem;
    min-width: 0.2rem;
    animation: intermittent 0.8s 2;
    animation-delay: 0.4s;
    overflow: hidden;
}

/* Estilos para la aparicion de logoGtorres. */
.display-logoGtorres {
    width: 0rem;
    height: 4rem;
    animation: curtain-logo2 1.3s forwards;
    animation-delay: 3.7s;
    overflow: hidden;
}

/* Estilos para la aparicion de logoDeveloper. */
.display-logoDeveloper {
    width: 0px;
    animation: curtain-logo2 1s forwards;
    animation-delay: 5.3s;
    overflow: hidden;
}

/* Estilos para la aparicion de loading */
.display-loading {
    width: auto;
    text-align: center;
    opacity: 0;
    overflow: hidden;
    animation: loading 0.1s forwards;
    animation-delay: 6.8s;
}


/********** Configuracion de las distintas salidas de las imagenes **********/

/* Estilos para la ocultacion de la intro(logoG). */
.out-intro1 {
    animation: out-logo1 2s forwards;
    animation-delay: 1.5s;
}

/* Estilos para la ocultacion de la intro(logoGtorres, logoDeveloper, logoDivisor). */
.out-intro2 {
    animation: out-logo2 1s forwards;
    animation-delay: 0s;
    overflow: hidden;
}

/* Estilos para el desvanecimiento del contenedor principal de la intro. */
.out-intro3 {
    transition: background-color 2s ease;
    background-color: #282c3400;
    transition-delay: 1.5s;
}

/* Estilos para la ocultacion de loading */
.out-loading {
    animation: out-loading 0s forwards;
    animation-delay: 0s;
}


/********** Configuracion de los efectos de las imagenes **********/

/* Efecto de parpadeo pa el logoDivisor. */
@keyframes intermittent {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Efecto de aparicion del logoG */
@keyframes curtain-logo1 {
    to {
        width: 7rem;
    }
}

/* Efecto de aparicion para el logoGtorres y logoDeveloper. */
@keyframes curtain-logo2 {
    to {
        width: 20.6rem;
    }
}

/* Efecto de aparicion de loading. */
@keyframes loading {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Efecto de traslacion y encojimiento para logoG. */
@keyframes out-logo1 {
    from {
        position: relative;
        top: 0vh;
        right: 0vw;
        transform: translate(0%, 0%);
        opacity: 1;
    }

    to {
        position: relative;
        top: -50vh;
        right: 50vw;
        transform: translate(50%, 50%);
        scale: 50%;
        opacity: 0;
    }
}

/* Efecto de ocultacion para logoGtorres, logoDeveloper y logoDivisor. */
@keyframes out-logo2 {
    from {
        width: 20.6rem;
    }

    to {
        width: 0rem;
    }
}

/* Efecto de ocultacion de loading */
@keyframes out-loading {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


/********** Configuracion responsive **********/

/* Configuracion responsive para dispositivos de pantallas pequeñas. */
@media (max-device-width: 767px) {
    .subcontainer-logo {
        gap: 0.1rem;
    }

    .logoG {
        width: 3rem;
        height: 3rem;
    }

    .logoDivisor {
        height: 3rem;
    }

    .logoGtorres {
        width: 10.3rem;
        height: 2rem;
    }

    .logoDeveloper {
        width: 10.3rem;
        height: 0.55rem;
    }

    .display-logoGtorres {
        height: 2rem;
    }

    .display-logoDeveloper {
        height: 2rem;
    }

    @keyframes curtain-logo1 {
        to {
            width: 4rem;
        }
    }

    @keyframes curtain-logo2 {
        to {
            width: 10.3rem;
        }
    }

    @keyframes out-logo2 {
        from {
            width: 10.3rem;
        }
    
        to {
            width: 0rem;
        }
    }
}

/* Configuracion responsive para dispositivos de pantallas medianas. */
@media (min-device-width: 768px) and (max-device-width:1023px) {}

/* Configuracion responsive para dispositivos de pantallas grandes. */
@media (min-device-width: 1023px) {}