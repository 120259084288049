 /* Estilos para el contenedor principal de la barra de menu. */
 .bar-container {
	display: flex;
	margin: auto;
	margin-bottom: 0rem;
	padding: 1rem 1rem 0rem 1rem;
	gap: 1rem;
	z-index: 1;
	background-color: #282c34;
}

/* Estilos para ubicar la barra de menu en la parte superior del navegador. */
.position-fixed {
	width: 100%;
	margin-top: 0;
}

/* Estilos para el logo de la barra de menu. */
.logo {
    width: 4rem;
    height: 4rem;
	min-width: 4rem;
	min-height: 4rem;
}

/* Estilos para el contenedor de los botones de la barra de menu. */
.bar-menu {
    display: flex;
    justify-content: center;
    align-items: center;
	white-space: nowrap;
	margin: auto;
}

/* Estilos para el boton de despliegue del menu. */
.button-menu {
	display: flex;
	width: 3rem;
	height: 3rem;
	min-width: 3rem;
	min-height: 3rem;
	margin: 0.5rem;
	margin-left: auto;
	padding: 0.5rem;
	background-color: #282c34aa;
	border: 2px solid #4ec3e0;
	border-radius: 0.5rem;
}

/* Estilos para el icono del boton del menu desplegable. */
.icon-menu {
	width: 2rem;
	height: 2rem;
	max-width: 100%;
	max-height: 100%;
}

/* Estilos para los botones del menu. */
.bar-button {		
	position: relative;	
	padding: 0.8rem 1.2rem;
	font-size: large;
	color: #4ec3e0ff;
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	-webkit-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
	transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

/* Estilos base para los pseudo-elementos de los botones. */
.bar-button:before, 
.bar-button:after {
	content: '';
	position: absolute;
	width: 0;
	height: 100%;
	top: 0;
	z-index: -1;
	-webkit-transition: inherit;	
	transition: inherit;
}

/* Estilos específicos para el pseudo-elemento ::before. */
.bar-button:before {
	right: 0;
	border: 1px solid #ff5c00;
	border-left: 0;
	border-right: 0;	
}

/* Estilos específicos para el pseudo-elemento ::after. */
.bar-button:after {
	left: 0;
}

/* Establece el modelo de caja para todos los elementos, pseudo-elementos ::before y ::after. */
*, *:before, *:after {
	box-sizing: border-box;
}

/* Configuracion responsive para dispositivos de pantallas pequeñas. */
@media (max-device-width: 767px) {
	.bar-container {
		margin-top: 0;
		width: 100%;
	}

	.bar-menu {
		display: none;
	}

	.bar-menu.deployed {
		display: flex;
		flex-direction: column;
	}

	.bar-button {
		padding-inline: 0;
	}
}

/* Configuracion responsive para dispositivos de pantallas medianas. */
@media (min-device-width: 768px) and (max-device-width:1023px) {
	.bar-container {
		margin-top: 0;
		width: 100%;
	}

	.bar-menu {
		display: none;
	}

	.bar-menu.deployed {
		display: flex;
		flex-direction: column;
	}
}

/* Configuracion responsive para dispositivos de pantallas grandes */
@media (min-device-width: 1023px) {
	.button-menu {
		display: none;
	}

	.bar-button:hover {
		color: #282c34;
		-webkit-transition-delay: .5s;
				transition-delay: .5s;
	}
	
	.bar-button:hover:before {
		width: 100%;
		-webkit-transition-delay: 0s;
				transition-delay: 0s;
	}
	
	.bar-button:hover:after {
		width: 100%;
		background: #4ec3e0ff;
		-webkit-transition-delay: .35s;
				transition-delay: .35s;
	}
}