/* Estilo para el  contenedor principal del formulario. */
.form-container {
    max-width: 30rem;
    width: 100%;
    padding: 2rem;
    border: 2px solid #4ec3e0;
    border-radius: 1rem;
    background: #282c34cc;
}

/* Estilo para el formulario. */
.form-container Form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: whitesmoke;
}

/* Estilos para los campos de texto. */
.field {
    margin: 0.5rem 0;
    margin-bottom: 1.5rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    max-width: 30rem;
    font-size: large;
}

/* Estilos para los labels */
label {
    font-size: large;
    text-align: left;
}

/* Estilo para el textArea */
.area {
    margin: 0.5rem 0;
    padding: 0.3rem;
    border-radius: 0.5rem;
    max-width: 100%;
    height: 10rem;
    resize: none;
    font-size: large;
    font-family: sans-serif;
}

/* Configuracion responsive para dispositivos moviles. */
@media (max-device-width: 767px) {
    .form {
        max-width: none;
        width: 100%;
    }
}

/* Configuracion responsive para tablets */
@media (min-device-width: 768px) and (max-device-width:1023px) {

}

/* Configuracion responsive para pantallas grandes */
@media (min-device-width: 1023px) {

}