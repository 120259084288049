html, body, #root {
	color: whitesmoke;
    width: 100%;
    height: 100%;
}

body {
    overflow: hidden;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100lvh;
    z-index: -1;
}

.app-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 100%;
    /* overflow: auto; */
}

button {
    padding: 15px 30px;
    margin-left: auto;
    border: none;
    border-radius: 0.5rem;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    color: whitesmoke;
    background-color: #ff5c00;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 10rem;
}
  
button:hover {
    background-color: #4ec3e0;
}
  
button:active {
    background-color: #282c34;
}

@media (max-width: 767px) {
}
  
@media (min-width: 768px) and (max-width:1023px) {
}
  
@media (min-device-width: 1023px) {
}
